<template>
    <dashboard-main>
        <div slot="main-content">
            <div class="admin-page-title">
                <small>Testimonies</small>
            </div>

            <div class="card">
                <div class="card-body">
                    <h6 class="mb-4">Testimonies Collection</h6>
                    <div class="table-responsive">
                        <table class="table" width="100%">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Testimony</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(testimony, index) in testimonies" :key="index">
                                    <td>
                                        <b>{{ testimony.id }}</b>
                                    </td>
                                    <td>
                                       {{testimony.name}}
                                    </td>
                                    <td>{{testimony.title}}</td>
                                    <td>{{testimony.testimony}}</td>
                                    <td>
                                        <button class="btn btn-danger btn-details" @click="delTestimony(testimony.id)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </dashboard-main>
</template>

<script>
import DashboardMain from "@/components/layouts/DashboardMain"
import { mapState } from 'vuex'

export default {
    name: "Testimony",
    components: {
        DashboardMain
    },
    data() {
        return {
            testimonies: [],
            testimonyId: null,
            testimony: {}
        }
    },
    mounted() {
        this.get()
        this.$store.commit('setToken')
    },
    methods: {
        get() {
            this.$axios.get(`${this.$apiurl}/testimonies/`)
            .then(res => {
                this.testimonies = res.data
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        async getTestimony(id) {
            try {
                const res = await this.$axios.get(`${this.$apiurl}/testimonies/${id}/`, {
                    headers: {
                        Authorization: `Token ${this.token}`
                    }
                })
                
                this.testimony = res.data
                this.testimonyId = id
            }catch(error) {
                console.log(error.response)
            }
        },
        async delTestimony(id) {
            this.$swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover it!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    this.$axios.delete(`${this.$apiurl}/testimonies/${id}/`, {
                        headers: {
                            Authorization: `Token ${this.token}`
                        }
                    })
                    .then(() => {
                        this.get()
                        this.$swal("Poof! Testimony has been deleted!", {
                            icon: "success",
                        });
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
                }
            });

            /*try {
                await this.$axios.delete(`${this.$apiurl}/testimonies/${id}/`, {
                    headers: {
                        Authorization: `Token ${this.token}`
                    }
                })
                
                this.get()
            }catch(error) {
                console.log(error.response)
            }*/
        },
        closeUpdate() {
            this.testimonyId = null 
            this.testimony = {}
            this.get()
        }
    },
    computed: {
        ...mapState([
            'token'
        ])
    }
}
</script>